<template>
  <div class="opt-hero">
    <div class="container">
      <div class="opt-hero__logo">
        <svg width="646" height="92" viewBox="0 0 646 92" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3066_1206)">
          <path d="M587.141 41.46C587.141 59.26 575.841 71.61 561.141 71.61C558.023 71.6839 554.929 71.0354 552.103 69.7153C549.277 68.3951 546.795 66.439 544.851 64V91.19H527.051V12.68H544.851V19.18C546.78 16.6692 549.274 14.6483 552.131 13.281C554.987 11.9137 558.125 11.2385 561.291 11.31C575.631 11.31 587.141 23.56 587.141 41.46ZM544.851 41.46C544.851 49.52 550.091 54.65 557.001 54.65C563.911 54.65 569.141 49.52 569.141 41.46C569.141 33.4 563.911 28.27 557.001 28.29C550.091 28.31 544.851 33.5 544.851 41.46Z" fill="white"/>
          <path d="M516.589 41.46C516.589 58.84 503.289 71.61 486.439 71.61C469.589 71.61 456.289 58.74 456.289 41.46C456.289 33.4637 459.466 25.7949 465.12 20.1407C470.774 14.4865 478.443 11.31 486.439 11.31C494.435 11.31 502.104 14.4865 507.758 20.1407C513.413 25.7949 516.589 33.4637 516.589 41.46ZM474.299 41.46C474.299 49.52 479.639 54.65 486.439 54.65C493.239 54.65 498.589 49.52 498.589 41.46C498.589 33.4 493.349 28.27 486.439 28.29C479.529 28.31 474.299 33.5 474.299 41.46Z" fill="white"/>
          <path d="M638.47 12.67V29.42H621.08V53.5H645.25V70.25H620.06C617.857 70.2513 615.675 69.8185 613.639 68.9763C611.603 68.1341 609.754 66.8991 608.195 65.3417C606.637 63.7844 605.401 61.9352 604.557 59.8999C603.714 57.8646 603.28 55.6831 603.28 53.48V29.42H593.42V12.67H603.28V0H621.08V12.67H638.47Z" fill="white"/>
          <path d="M35.38 14.64L36 22.8H36.24C39.67 16.41 44.52 13.46 50.67 13.46C58.49 13.46 62.67 16.65 65.34 22.8H65.58C70.43 15.35 76.12 13.46 81.08 13.46C95.87 13.46 98.71 23.63 98.71 34.16V70.25H79.75V38.54C79.75 33.45 79.99 28.48 74.55 28.48C68.87 28.48 67.92 32.98 67.92 38.18V70.25H49V38.54C49 33.45 49.23 28.48 43.79 28.48C38.11 28.48 37.16 32.98 37.16 38.18V70.25H18.46V36.88C13.73 37 8.64 34.28 8.87 27.18C3.55 26.83 0 23.16 0 16.3V14.64H35.38Z" fill="white"/>
          <path d="M122.929 71.2C113.929 71.2 105.189 67.2 105.189 54.87C105.189 32.74 138.319 43.39 138.319 31.56C138.319 28.84 136.659 27.18 132.399 27.18C128.139 27.18 125.299 29.66 124.349 34.87L107.429 32.15C110.429 19.02 120.429 13.46 133.109 13.46C147.659 13.46 157.249 18.19 157.249 34.04V50C157.249 51.77 157.599 65.85 157.959 70.23H140.999L140.289 64.08H140.049C136.049 69.08 129.989 71.18 122.889 71.18L122.929 71.2ZM124.589 53C124.589 56.31 126.959 58.08 130.269 58.08C135.599 58.08 138.549 54.65 138.549 49.33V44.83C133.549 48.73 124.549 46.49 124.549 53" fill="white"/>
          <path d="M191.68 77.47C197.68 77.47 200.79 74.98 200.79 67.89V61.5H200.55C197.71 65.4 192.15 68.36 186 68.36C171.44 68.36 164.58 56.76 164.58 41.02C164.58 25.52 172.39 13.46 186.83 13.46C193.21 13.46 198.42 17.36 201.14 20.79H201.38L201.73 14.64H219.73V62C219.73 82.82 210.03 91.22 191.45 91.22C181.27 91.22 171.45 87.67 166.72 75.84L182.72 71.84C184.38 75.74 186.98 77.52 191.72 77.52L191.68 77.47ZM201.42 41.19C201.42 32.44 198.58 28.06 192.78 28.06C186.98 28.06 183.91 32.44 183.91 41.19C183.91 50.3 186.91 54.68 192.78 54.68C198.65 54.68 201.42 50.3 201.42 41.19Z" fill="white"/>
          <path d="M264.8 13.46C277.93 13.46 281 23.39 281 35V70.25H262.07V38.66C262.07 33.57 261.84 28.48 255.45 28.48C249.45 28.48 248.11 33.57 248.11 38.78V70.25H229.18V14.64H247.18L247.65 22.45H247.88C252.5 16.29 257.82 13.45 264.81 13.45" fill="white"/>
          <path d="M306.439 71.43C293.539 71.43 290.439 61.5 290.439 49.9V14.64H309.399V46.23C309.399 51.32 309.629 56.41 315.789 56.41C321.589 56.41 322.889 51.32 322.889 46.11V14.64H341.819V70.25H323.819L323.349 62.44H323.109C318.619 68.59 313.289 71.44 306.429 71.44" fill="white"/>
          <path d="M368.319 14.64L368.909 22.8H369.149C372.579 16.41 377.669 13.46 383.819 13.46C391.749 13.46 395.889 16.65 398.609 22.8H398.849C403.699 15.35 409.499 13.46 414.469 13.46C429.379 13.46 432.209 23.63 432.209 34.16V70.25H413.279V38.54C413.279 33.45 413.519 28.48 407.959 28.48C402.159 28.48 401.209 32.98 401.209 38.18V70.25H382.279V38.54C382.279 33.45 382.519 28.48 376.959 28.48C371.159 28.48 370.209 32.98 370.209 38.18V70.25H351.279V14.64H368.319Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_3066_1206">
          <rect width="645.25" height="91.19" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>
      <h1>{{$t('opt')}}</h1>
      <a href="https://magnumopt.kz/" target="_blank" class="btn">Оптовый сайт</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OptHero'
  }
</script>